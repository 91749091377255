<template>
  <v-container fluid>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!--BOC:[title]-->
    <APageTitle title="Components"/>
    <!--EOC-->
    <div class="mb-1"></div>
    <!--BOC:[dashboard]-->
    <ADashboard :modules="modules"/>
    <!--EOC-->
  </v-container>
</template>

<script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
    //BOC:[dashboard]
    modules: [
      {
        name: 'BreadTabulator',
        route: { name: 'PageDeveloperComponentBreadTabulator' },
      },

      {
        name: 'Browse Products',
        route: { name: 'PageServiceModelBreadTabulatorBrowse', params: { modelKey: 'demoProduct'}, query: {
            viewId: 'all',
          }, },
      },

      {
        name: 'Browse Categories',
        route: { name: 'PageServiceModelBreadTabulatorBrowse', params: { modelKey: 'demoCategory'}, query: {
            viewId: 'all',
          }, },
      },

      {
        name: 'Browse Customers',
        route: { name: 'PageServiceModelBreadTabulatorBrowse', params: { modelKey: 'demoCustomer'}, query: {
            viewId: 'all',
          }, },
      },

      {
        name: 'Browse Orders',
        route: { name: 'PageServiceModelBreadTabulatorBrowse', params: { modelKey: 'demoOrder'}, query: {
            viewId: 'all',
          }, },
      },

       /* {
        name: 'Browse Template',
        route: { name: 'PageDeveloperComponentBreadTabulatorBrowseTemplate'}
      },  */

    ],
    //EOC
  }),
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Developer",
      to: { name: "PageServiceDashboard", params: { serviceKey: "developer" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Components",
      to: { name: "PageDeveloperComponent" },
      exact: true,
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>